<template>
  <v-main>
    <v-container fluid class="full-height  px-10">
      <!--RETURN-->
      <div>
        <v-row>
          <v-col>
            <v-btn
              class="pl-0"
              text
              @click="$router.go(-1)"
              style="color: #173466"
            >
              <v-icon size="x-large">mdi-chevron-left</v-icon>
              <span class="text-body-1"> {{ $t("back") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <coasts-alerts-header :region="activeRegion"></coasts-alerts-header>
      <v-divider class="mt-5"></v-divider>
      <v-divider class="mb-5"></v-divider>

      <coasts-alerts-body
        v-if="!loading"
        :parameter-list="parameterList"
        :existing-thresholds="existingThresholds"
        @reload="reloadThresholds"
      ></coasts-alerts-body>
    </v-container>
  </v-main>
</template>

<script>
import CoastsAlertsHeader from "@/core/components/coastsAlerts/CoastsAlertsHeader.vue";
import regionMixin from "@/core/mixins/region.mixin";
import axios from "axios";
import CoastsAlertsBody from "@/core/components/coastsAlerts/CoastsAlertsBody.vue";

export default {
  name: "CoastsAlerts",
  components: { CoastsAlertsBody, CoastsAlertsHeader },
  mixins: [regionMixin],
  computed: {
    activeRegion() {
      return this.getActiveRegion(this.regions, this.$route.params.regionId);
    }
  },
  data: () => ({
    loading: true,
    parameterList: [],
    existingThresholds: []
  }),
  methods: {
    async fetchTideParameter() {
      const response = await axios.get(`/external/qld/tides/parameters`);
      const filtered = response.data.filter(
        parameter => parameter.name === "Water Level"
      );
      const tideObject = [
        {
          full_name: "Tide Level (LAT)",
          name: "Water Level",
          data: filtered,
          type: "tides",
          unit: "m"
        }
      ];
      return tideObject;
    },
    async fetchWaveParameter() {
      const response = await axios.get(`/external/qld/waves/parameters`);
      const filtered = response.data.filter(
        parameter => parameter.name == "Hsig"
      );
      return filtered;
    },
    async fetchCoastsThresholds() {
      try {
        const response = await axios.get(`/coasts/thresholds`);
        return response.data;
      } catch {
        //
      }
    },
    async reloadThresholds() {
      this.loading = true;
      let parameterList = await this.fetchTideParameter();
      this.parameterList = parameterList.concat(
        await this.fetchWaveParameter()
      );
      this.existingThresholds = await this.fetchCoastsThresholds();
      this.loading = false;
    }
  },
  async created() {
    let parameterList = await this.fetchTideParameter();
    this.parameterList = parameterList.concat(await this.fetchWaveParameter());
    this.existingThresholds = await this.fetchCoastsThresholds();
    this.loading = false;
  }
};
</script>

<style scoped></style>
