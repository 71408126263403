<template>
  <v-row align="center" justify="center">
    <v-card width="80%" class="py-5">
      <v-row class="px-5" align="center">
        <v-col cols="5">{{ parameter.name }} - {{ parameter.full_name }}</v-col>
        <v-col cols="auto">
          <v-icon color="red">mdi-alert-outline</v-icon>
        </v-col>
        <v-col cols="3">
          <v-text-field
            dense
            hide-details
            v-model="threshold"
            :label="$t('thresholdSettings.currentThreshold')"
            type="Number"
            step="0.1"
          ></v-text-field>
        </v-col>
        <v-col>{{ parameter.unit }}</v-col>
        <v-col cols="auto">
          <v-btn @click="emitThreshold" icon>
            <v-icon :color="iconColor">mdi-content-save</v-icon>
          </v-btn>
        </v-col>
        <v-col v-if="this.parameter.thresholdObject">
          <v-btn @click="deleteThreshold" icon>
            <v-icon color="primary">mdi-delete</v-icon>
          </v-btn>
        </v-col>
        <v-col v-else></v-col>
      </v-row>
    </v-card>
  </v-row>
</template>
<script>
import axios from "axios";

export default {
  name: "thresholdCard",
  props: {
    parameter: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    threshold: null,
    setThreshold: false,
    saveNeeded: false,
    loaded: false
  }),
  computed: {
    iconColor() {
      return this.saveNeeded ? "green" : "grey";
    }
  },
  methods: {
    emitThreshold() {
      this.$emit("setThreshold", {
        parameter: this.parameter.name,
        threshold: this.threshold
      });
      this.saveNeeded = false;
    },
    async deleteThreshold() {
      this.saveNeeded = false;
      const id = this.parameter.thresholdObject.id;
      await axios.delete(`/coasts/thresholds/${id}/`);
      this.$emit("reload");
      this.threshold = null;
    }
  },
  created() {
    if (this.parameter?.thresholdObject) {
      this.threshold = this.parameter.thresholdObject.threshold;
    }
    this.setThreshold = this.parameter.threshold;
    this.loaded = true;
  },
  watch: {
    threshold(newVal, oldVal) {
      if (this.threshold && oldVal) {
        this.saveNeeded = true;
      }
    }
    // setThreshold() {
    //   if (this.setThreshold == false) {
    //     this.saveNeeded = false;
    //     this.deleteThreshold();
    //     this.threshold = null;
    //   }
    // }
  }
};
</script>

<style scoped></style>
