<template>
  <div class="mt-2 pl-3">
    <v-row class="mb-3 mt-5">
      <span class="text-h6">{{ $t("coastsAlerts.title") }}</span>
    </v-row>
    <v-row class="mb-3" style="width: 90%">
      <span class="text-body-1 mt-2">{{ $t("coastsAlerts.description") }}</span>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CoastsAlertsHeader",
  props: {
    region: {
      type: Object
    }
  }
};
</script>

<style scoped></style>
