<template>
  <v-container class="mt-5 mb-8 full-height" style="max-width: 100%;">
    <v-row
      justify="center"
      class="py-5"
      v-for="parameter in parameterListLocal"
      :key="parameter.full_name"
    >
      <threshold-card
        :parameter="parameter"
        @setThreshold="postThreshold"
        @reload="reloadThresholds"
      ></threshold-card>
    </v-row>
  </v-container>
</template>

<script>
import ThresholdCard from "@/core/components/coastsAlerts/thresholdCard.vue";
import axios from "axios";
import store from "@/core/store";

export default {
  name: "CoastsAlertsBody",
  components: { ThresholdCard },
  props: {
    parameterList: {
      type: Array,
      default: () => []
    },
    existingThresholds: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    parameterListLocal() {
      return this.parameterList;
    }
  },
  methods: {
    async postThreshold(data) {
      const alreadyThreshold = this.existingThresholds.filter(
        thres => thres.parameter == data.parameter
      );
      try {
        if (!alreadyThreshold.length) {
          await axios.post(`/coasts/thresholds/`, data);
        } else {
          const id = alreadyThreshold[0].id;
          await axios.put(`/coasts/thresholds/${id}/`, data);
        }
      } catch {
        store.dispatch("app/showSnackbar", {
          show: true,
          message: `Please insert a valid input for your threshold.`,
          color: "error"
        });
      }

      this.reloadThresholds();
    },
    reloadThresholds() {
      this.$emit("reload");
    },
    matchThresholds() {
      this.parameterList.forEach(para => {
        const filtered = this.existingThresholds.filter(
          thres => para.name === thres.parameter
        );
        if (filtered.length) {
          para.threshold = true;
          para.thresholdObject = filtered[0];
        } else {
          para.threshold = false;
        }
      });
    }
  },
  created() {
    this.matchThresholds();
  }
};
</script>

<style scoped></style>
